:local .nav {
  max-width: 72px;
  width: 72px;
  height: 100vh;
  background-color: #00ACA9;
  position: relative;
  min-width: 72px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0;
  }

  .navbar {
    margin-top: 5px;
  }

}