$color-primary: #00ACA9;
$color-secondary: #FB7676;

input[type="radio"] + label:before {
    border: 1px solid #969696;
    content: " ";
    display: inline-block;
    height: 12px;
    margin: 0 .25em 0 0;
    padding:0;
    vertical-align: top;
    width: 12px;
    border-radius:12px;
}

input[type="radio"]:checked + label:before {
    background: $color-primary;
    border: 1px solid $color-primary;
    color: #FFF;
    content: " ";
    text-align: center;
}

input[type="radio"]:checked + label:after {
    font-weight: bold;
}

input[type="radio"]:checked + .radioRed:before {
    background: $color-secondary;
    border: 1px solid $color-secondary;
    color: #FFF;
    content: " ";
    text-align: center;
}

input[type="radio"]:checked + .radioRed:after {
    font-weight: bold;
}